@import '../../assets/styles/variables';

.popup-window{
    margin: $grid-gutter 0 $grid-gutter * 2;
    border: 2px solid $black;
    background: $yellow;
    color: $black;
    top: 0;
    left: 0;

    @include media-breakpoint-up(md){
        margin: $grid-gutter 0;
    }

    .popup-text{
        padding: $grid-gutter $grid-gutter / 2;
        font-family: $spoof;
        font-size: 1.5em;
    }

    .popup-header{
        height: $grid-gutter;
        background: $white;
        border-bottom: 2px solid $black;

        i{
            position: absolute;
            right: 10px;
            font-size: 40px;
            top: 3px;
        }
    }

    &:nth-of-type(2){
        position: absolute;
        transform: translateY(20px);

        @include media-breakpoint-up(md){
            transform: translate(-$grid-gutter, -2 * $grid-gutter);
        }
    }

    &:nth-of-type(3){
        position: absolute;
        transform: translateY(90px);

        @include media-breakpoint-up(md){
            transform: translate(-2 * $grid-gutter, -3 * $grid-gutter);
        }
    }
}