@import '../../assets/styles/variables';

#nomatch-wrap{
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    h1{
        color: $red;
        font-size: 120px;
        margin-bottom: $grid-gutter;
        display: flex;
        align-items: center;
        justify-content: center;

        span{
            padding: 0 $grid-gutter / 2;

            &:nth-child(1){
                padding-top: $grid-gutter / 2;
            }

            &:nth-child(2){
                padding-top: $grid-gutter;
            }
        }

        @include media-breakpoint-up(md){
            font-size: 200px;
            line-height: 1em;

            span{
                padding: 0 $grid-gutter;

                &:nth-child(1){
                    padding-top: $grid-gutter * 1;
                }

                &:nth-child(2){
                    padding-top: $grid-gutter * 2;
                }
            }
        }
    }
}