@import "../../assets/styles/variables";

.range-selector {
  height: 100%;
}

.next-button {
  margin: 0 auto;
  background: $black;
  width: 100px;
  height: 50px;
  color: $white;
  border-radius: 0;
  display: flex;
  justify-content: center;
}

.slider-conponent-container {

  @include media-breakpoint-up(md){
    display: flex;
  }
}

#sliderInput {    
    transform: rotate(-90deg) translateY(-50%);
    height: 0;
    padding-top: 100%;
    color: $beige;
    background: #EDE9E4;
    

    @include media-breakpoint-up(md){
      padding-top: 0;
      transform: rotate(0);
    }
}

.slider:-webkit-slider-thumb{
    width: 50px;
}

.slider:-moz-range-thumb {
    width: 50px;
}

.slider-wrapper {
    display: flex;    
    flex-direction: column-reverse;
    padding: 0;

    @include media-breakpoint-up(md){
      flex-direction: row;
      margin: $grid-gutter * 2 auto;
      align-items: center;
    }

    .legend{
      padding: 0 10px;
    }
}

.next-button {
    margin-top: 20px;
    width: 150px;
    cursor: pointer;
}

// HTML Range styling
$range-button-color: $purple;
$range-button-diameter: 30px;
$range-track-color: $grey;
$range-track-height: 7px;

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  margin: 11.5px 0;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: $range-track-height;
  cursor: pointer;
  box-shadow: 0;
  background: $range-track-color;
  border-radius: 0px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031, 0px 0px 0px #00004b;
  border: 0px solid #00001e;
  height: $range-button-diameter;
  width: $range-button-diameter;
  border-radius: $range-button-diameter;
  background: $range-button-color;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: $range-track-color;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: $range-track-height;
  cursor: pointer;
  box-shadow: 0;
  background: $range-track-color;
  border-radius: 0px;
  border: 0;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 0;
  border: 0;
  height: $range-button-diameter;
  width: $range-button-diameter;
  border-radius: $range-button-diameter;
  background: $range-button-color;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: $range-track-height;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: $range-track-color;
  border: 0;
  border-radius: $range-button-diameter;
  box-shadow: 0;
}
input[type=range]::-ms-fill-upper {
  background: $range-track-color;
  border: 0px solid #010101;
  border-radius: $range-button-diameter;
  box-shadow: 0;
}
input[type=range]::-ms-thumb {
  box-shadow: 0;
  border: 0;
  height: $range-button-diameter;
  width: $range-button-diameter;
  border-radius: $range-button-diameter;
  background: $range-button-color;
  cursor: pointer;
  height: $range-track-height;
}
input[type=range]:focus::-ms-fill-lower {
  background: $range-track-color;
}
input[type=range]:focus::-ms-fill-upper {
  background: $range-track-color;
}
