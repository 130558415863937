// Bootstrap Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

// Gutter
$grid-gutter: 50px;
$wrap-sm: 576px;
$wrap-md: 768px;
$wrap-lg: 992px;
$wrap-xl: 1200px;

//Colors
$white: #ffffff;
$black: #111111;
$red: #F2705E;
$blue: #009DE0;
$yellow: #F8D807;
$green: #3ABA89;
$purple: #AC7EB7;
$grey: #BFBFBF;
$beige: #EDE9E4;
$lightgrey: #F5F5F5;

// Fonts
@font-face {
    font-family: 'spoof';
    src: url('../fonts/Spoof-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Spoof-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/Spoof-Bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/Spoof-Bold.woff') format('woff'), /* Pretty Modern Browsers */
    /*url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
    /*url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
}
$anon-pro:"Anonymous Pro", monospace;
$spoof: "spoof", serif;

// Mixins
@mixin headerFooter{
    width: 100%;
    min-height: $grid-gutter;
    background: $black;
    color: $white;
    position: fixed;
    margin:0 auto;
    background-size: 48%; // Making sure it shows a bar at the end

    @include media-breakpoint-up(md){
        background-size: 48%; // Making sure it shows a bar at the end
    }

    @include media-breakpoint-up(lg){
        background-size: 20.37%; // Making sure it shows a bar at the end
    }
}

@mixin typeColors {
    &.blue{
        background-color: $blue;
    }
    &.yellow{
        background-color: $yellow;
        color: $black;
    }
    &.grey{
        background-color: $grey;
        color: $black;
    }
    &.red{
        background-color: $red;
    }
    &.green{
        background-color: $green;
    }
    &.black{
        background-color: $black;
        color: $white;
    }
  }