@import '../../assets/styles/variables';

#aboutPage{
    background-color: $white;
    color: $black;
    padding: 0;

    section{
        padding: 100px 0;

        h2{
            margin-top: $grid-gutter;

            &:first-child{
                margin-top: 0;
            }
        }

        h4{
            text-align: left;
            font-size: 1em;
        }

        h5{
            text-transform: uppercase;
            font-size: .9em;
            margin-bottom: 0;
        }

        h6{
            font-family: $anon-pro;
            font-style: italic;
            font-weight: 400;
            font-size: .9em;
            margin-top: 0;
        }
    }

    ul{
        @include media-breakpoint-up(md){
            columns: 2;

            &.video-credits{
                margin-bottom: $grid-gutter * 2;
            }
            
            li{
                padding-right: $grid-gutter / 2;
                break-inside: avoid;
            }
        }

        @include media-breakpoint-up(lg){
            &.video-credits{
                columns: 3;
            }
        }

        &.video-credits{
            margin-bottom: $grid-gutter;

            li{
                margin-bottom: 20px;
            }
        }

        li{
            margin-bottom: 10px;
            line-height: 1.5em;
        }
    }

    #team{
        background-color: $lightgrey;
    }

    #knight{
        background-color: $black;
        color: $white;
        
        @include media-breakpoint-up(md){
            padding-bottom: 100px;
        }
    }

    #about{
        @include media-breakpoint-up(md){
            padding-top: 100px;
        }
    }
}