@import '../../assets/styles/variables';

.App-header {
  @include headerFooter;
  background-image: url("../../assets/images/blue-bars.svg");
  top: 0;

  @media only screen and (max-height: 600px) {
    height:10vh;
  }
}
