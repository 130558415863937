@import '../../assets/styles/variables';

.video-block{
    position: relative;
    z-index: 1;
    margin-bottom: $grid-gutter / 2;

    .video-play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: $grid-gutter;
        transition: .25s;
        cursor: pointer;
    }

    .background{
        position: absolute;
        top:10px;
        left: 10px;
        width: 100%;
        height: 100%;
        background: $red;
        z-index: -1;
    }

    &:hover{
        .video-play{
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}