@import '../../assets/styles/variables.scss';

html{
  //scroll-behavior: smooth;
  margin: 0;
  background-color: $black;
  font-size: 18px;

  *:focus{
    outline: 0;
  }
}

html, body{
  width: 100vw;
  overflow-x: hidden;
  overscroll-behavior-x: none;
}

.desktop-only{
  display: none;
}

@include media-breakpoint-up(lg){
  .mobile-only{
    display: none;
  }

  .desktop-only{
    display: inherit;
  }
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	background-color: $black;
	min-height: 100vh;
	color: $white;
	font-family: $anon-pro;
	
	.wrap{
    width: 100%;
		margin: 0 auto;
    padding: 0 $grid-gutter / 2;
    
    @include media-breakpoint-up(md){
      max-width: $wrap-md;
    }
    
    @include media-breakpoint-up(lg){
      max-width: $wrap-lg;
    }
	}
}

// Splash page styling
.app-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  min-height: 100vh;
  overflow: hidden;
  
  &>*:first-child{
    padding: $grid-gutter * 2 0;
  }
}

//Typography


h1{
	font-family: $spoof;
  font-size: 2em;
  margin-top: 0;

  @include media-breakpoint-up(md){
    font-size: 2.5em;
  }
}

h2{
	font-family: $spoof;
  letter-spacing: .04em;
  font-size: 1.3em;
  margin-top: 0;

  @include media-breakpoint-up(md){
    font-size: 1.8em;
  }
}

h3{
  font-family: $spoof;
  margin-bottom: 10px;
  margin-top: 0;
}

h4{
  font-weight: 400;
  line-height: 1.3em;
  font-size: 1.2em;
  text-align: center;

  @include media-breakpoint-up(md){
    font-size: 1.4em;
  }
}

h5{
  font-weight: 400;
  font-size: 1.1em;
  line-height: 1.5em;
  margin-top: 0;
  
  @include media-breakpoint-up(md){
    font-size: 1.2em;
  }
}

h6{
  font-size: 1em;
  font-family: $spoof;
  margin-bottom: $grid-gutter / 2;
}

input, 
select{
  font-family: $anon-pro;
}

p{
  line-height: 1.5em;
  margin-top: 0;
}

ul{
  padding-left: 0;

  li{
    list-style: none;
  }
}

a{
  color: inherit;
  text-decoration: underline;
}

input{
  font-family: $anon-pro;
  font-size: 1em;
}

span.red{
    color: $red;
}

img, video{
  max-width: 100%;
}

.text-center{
  text-align: center;
}

.space-up{
  margin-top: $grid-gutter;
  
  @include media-breakpoint-up(md){
    margin-top: $grid-gutter * 2;
  }
}