@import '../../assets/styles/variables';

.select-box {
	margin: 0 auto;
	border: 2px solid #000;
	padding: 0 0;
	max-width: 400px;
	
	overflow: hidden;
	text-align: center;
	max-height:45%;
	position: relative;

	.select {
		position: relative;
		z-index: 99;
		border: 0;
		background: none;
		width: 100%;
		padding: 10px 20px;
		text-align-last: center;
		color: white;
		font-size: 1.2em;
		appearance: none;

		@include media-breakpoint-up(md){
			font-size: 1.5em;
		}

		option {
			background: $blue;
		}
	}

	.selectArrow{
		position: absolute;
		top: 50%;
		right: 15px;
		font-size: 1.5em;
		transform: translateY(-50%);
	}
}
