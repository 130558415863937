@import '../../assets/styles/variables';

#landingQuizWrap{
    ul{
        margin: $grid-gutter auto;
        padding-left: 0;
        text-align: left;
        color: $black;
    
        li{
            padding: 2.5%;
            margin-bottom: 10px;
            line-height: 1.3em;
            list-style: none;
            margin-left: -2.5%;
            width: 105%;

            .question{
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            p{
                color: $white;
                margin: 10px 0 0;
            }
    
            .checkbox{
                display: inline-block;
                border: 2px solid $yellow;
                width: 30px;
                height: 30px;
                display: inline-block;
                margin-right: $grid-gutter / 2;
                flex: 0 0 30px;
    
                &.active{
                    background-color: $yellow;
                }
            }

            &.right,
            &.wrong{
                .question{
                    cursor: default;
                }

                .checkbox{
                    position: relative;
                    background-color: $black;
                    border-color: $black;

                    img{
                        display: none;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        max-width: none;
                        top: -5px;
                        left: -5px;
                    }
                }
            }

            &.wrong{
                background-color: $red;

                .checkbox{
                    background-color: $black;
                    border-color: $black;

                    .cross{
                        display: block;
                    }
                }
            }

            &.right{
                background-color: $green;

                .checkbox{
                    background-color: $black;
                    border-color: $black;

                    .check{
                        top: -15px;
                        display: block;
                    }
                }
            }
        }
    }
}