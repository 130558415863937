@import "../../assets/styles/variables";

.app-header {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: $white;
  z-index: 999;
  
  .banner{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 30px;
    background: $black;
    width: 100%;
    height: $grid-gutter;

    .hamburger {
      position: absolute;
      left: 10px;
      padding: 5px 0;
      
      .hamburger-inner,
      .hamburger-inner::before, 
      .hamburger-inner::after{
        background-color: $white;
        width: 30px;
        height: 3px;
      }

      &:hover{
        opacity: 1;

        .hamburger-inner,
        .hamburger-inner::before, 
        .hamburger-inner::after{
          background-color: $yellow;
        }
      }
    }
  
    .title {
      margin-left: $grid-gutter / 2;
      width: 100%;
      text-align: center;
  
      a{
        text-decoration: none;
      }
  
      img{
        width: 100%;
        max-width: 400px;
      }
    }
  }

  .menu-wrap{
    z-index: -1;

      .menu{
        animation: menu-drop .25s ease-out;
        animation-fill-mode: forwards;
      }
      .menu-bg{
        animation: fade-in .25s ease;
      }
  }

  .menu-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .8);
    z-index: -1;
  }

  .menu{
    position: absolute;
    padding: $grid-gutter * 2 0 $grid-gutter;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: $black;
    transform: translateY(-100%);
    box-shadow: 0px 20px 40px 0px rgba($white,.05);
    min-height: 100vh;

    @include media-breakpoint-up(md){
      min-height: 0;
      padding: $grid-gutter * 2 0 $grid-gutter;
    }
  
    .page-link{
      margin-bottom: $grid-gutter / 2;
    }

    a{
        color: $white;
        font-family: $spoof;
        font-size: 1.2em;
        letter-spacing: .05em;
        text-decoration: none;

        &.active{
          color: $yellow;
        }

        &:hover{
          color: $yellow;
        }
    }

    .button{
      margin: 0;

      a{
        font-size: 1em;
        //border-color: $red;
        background-color: $black;
      }
      .shadow{
        background-color: $red;
      }
    }

    .social{
      margin-top: $grid-gutter / 2;
    }
  }
}

@keyframes menu-drop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: .8;
  }
}
