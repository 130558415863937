@import '../../assets/styles/variables';

.button{
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: .25s;
    cursor: pointer;
  
    a{
      text-decoration: none;
      padding: .8em 1em;
      display: inline-block;
      text-transform: uppercase;
      font-family: $spoof;
      border: 2px solid $white;
    }
  
    &:hover{
      border-color: $red;
  
      .shadow{
        top: 10px;
        left: 10px;
      }
    }
  
    .shadow{
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-color: $white;
      transition: .25s;
    }

    &.white-border{
        a{
            background-color: $black;
            border-color: $white;
            color: $white;
        }
        
        .shadow{
            background-color: $white;
        }
    }
  
    &.yellow{
        a{
          background-color: $yellow;
          border-color: $yellow;
          color: $black;
        }
        
        .shadow{
          background-color: $black;
        }
    }
  
    &.black{
      a{
        background-color: $black;
        border-color: $black;
        color: $white;
      }
  
      &:after{
        //background-color: $black;
      }
  
      &:hover{
        //background-color: $red;
      }
    }
  }