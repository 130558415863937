@import '../../assets/styles/variables';

.landing-news-chart-wrap{
    h3{
        text-align: center;
        margin-bottom: 0;
    }

    p{
        text-align: center;
        margin: 10px 0 5px;
    }

    .landing-seek-chart,
    .landing-info-chart{
        margin-top: $grid-gutter * 2;
        background: $white;
        color: $black;

        .header{
            padding: 15px;
            background-color: $purple;
            color: $white;

            @include media-breakpoint-up(md){
                padding: $grid-gutter / 2;
            }
        }

        .content{
            padding:  5px;

            @include media-breakpoint-up(md){
                padding:  $grid-gutter $grid-gutter / 2;
            }
        }
    }


    .landing-seek-chart{

        .content{
            position: relative;
            //padding:  $grid-gutter 0;

            .line{
                border: 1px solid rgba($black, .1);
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 0;
            }
        }

        .legend-wrap{
            display: flex;
            position: relative;
        }

        .legend1,
        .legend2{
            flex: 1 1 40%;
            text-align: center;
            padding: 0 10px;

            @include media-breakpoint-up(md){
                padding:  0 $grid-gutter / 2;
                font-size: 1.2em;
            }
        }

        .legend1{
            padding-left: 0;
            color: $green;
        }

        .legend2{
            padding-right: 0;
            color: $blue;
        }

        .results-wrap{
            display: flex;
        }

        .result-wrap1,
        .result-wrap2{
            width: 50%;
            background-color: $lightgrey;

            .result{
                height: $grid-gutter;
                display: flex;
                align-items: center;
                padding: 10px;
                color: $black;
            }
        }
        .result-wrap1{
            .result{
                float: right;
                background-color: $green;
            }
        }

        .result-wrap2{
            .result{
                background-color: $blue;
                justify-content: flex-end;
            }
        }

        .voters{
            .result-wrap1{
                .result{
                    width: 73%;
                }
            }
    
            .result-wrap2{
                .result{
                    width: 27%;
                }
            }
        }
        
        .non-voters{
            .result-wrap1{
                .result{
                    width: 56%;
                }
            }
    
            .result-wrap2{
                .result{
                    width: 44%;
                }
            }
        }
    }

    .landing-info-chart{

        .results-wrap{
            display: flex;

            .result1,
            .result2,
            .result3{
                height: $grid-gutter;
                display: flex;
                align-items: center;
                padding-left: 10px;
                overflow: hidden;
            }

            .result1{
                background-color: $green;
            }

            .result2{
                background-color: $red;
            }

            .result3{
                background-color: $grey;
            }

            &.voters{
                .result1{
                    flex: 1 1 80%;
                }
                .result2{
                    flex: 1 1 15%;
                }
                .result3{
                    flex: 1 1 5%;
                }
            }
            
            &.non-voters{
                .result1{
                    flex: 1 1 59%;
                }
                .result2{
                    flex: 1 1 29%;
                }
                .result3{
                    flex: 1 1 12%;
                }
            }
        }
    }
}