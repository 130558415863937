@import '../../assets/styles/variables';

#typesPage{
    background-color: $purple;

    section{
        padding: $grid-gutter * 2 0;
    }

    #intro{
        display: flex;
        padding: 200px 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    #typeChart{
        display: none;
        
        .types-wrap{
            display: flex;

            .type{
                position: relative;

                p{
                    position: absolute;
                    top: -50px;
                    width: 130px;              
                    line-height: 1.2em;
                }

                &:nth-child(2n){
                    p{
                        top: auto;
                        bottom: -70px;
                    }
                }

                &:last-child{
                    p{
                        text-align: right;
                        right: 0;
                    }
                }
            }

            .type-wrap{
                height: $grid-gutter;
                display: flex;
                align-items: center;
                padding-left: 10px;
                @include typeColors;
            }
        }
    }

    #allTypes{
        .type{
            padding: 15px;

            @include media-breakpoint-up(md){
                padding: $grid-gutter / 2;
            }

            h3{
                margin: 0;
            }

            h4{
                font-size: 1.2em;
                text-align: left;
                margin-top: 0;
            }

            .type-wrap{
                padding: $grid-gutter / 2;

                @include typeColors;
            }
        }
    }
}