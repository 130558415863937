@import '../../assets/styles/variables';

#videoPage{
    min-height: 100vh;
    background-color: $white;
    color: $black;

    #intro{
        margin-bottom: $grid-gutter;

        h1{
            margin-bottom: 10px;
        }

        @include media-breakpoint-up(md){
            margin-bottom: $grid-gutter * 2;
        }
    }

    .videos-wrap{
        .video{
            margin-bottom: $grid-gutter;

            @include media-breakpoint-up(md){
                margin-bottom: $grid-gutter * 2;
            }

            .video-thumbnail{
                position: relative;
                margin-bottom: 10px;
            }
        }
    }
}