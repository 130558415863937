@import '../../assets/styles/variables';

.splash-page {

  background: $black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $white;
  width: 100%;
  padding: $grid-gutter * 2 $grid-gutter / 2;
  box-sizing: border-box;
  flex: 1;

  .logo {
    width: 80%;
    max-width: 250px;
    margin-bottom: 30px;
  }

  a {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .upper {
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin: 0 auto 30px;
    height:40%;
    line-height: 1.5em;
  }

  p {
    font-family: $anon-pro;
  }

  .hash-tag {
    margin: 0.75em 0;
  }

  .social-icons {
    margin-top: 2vh;

    a{
      color: $white;

      &:hover{
        color: $yellow;
      }
    }

    i {
      font-size: 1em;
      padding: 0 $grid-gutter / 2;
    }
  }


  //MEDIA QUERIES
  @include media-breakpoint-up(md){
    padding: 100px 20px;

    .logo {
      max-width: 350px;
    }

    .upper{
      font-size: 1.2em;
    }
  }
}
