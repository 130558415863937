@import '../../assets/styles/variables';

.chart-header{
  display: flex;
  align-items: center;
  padding: 0 10px;

  h4{
    flex: 1;
    text-align: center;
  }

  .nav{
    font-size: $grid-gutter;
    padding: 0;
    cursor: pointer;
    transition: .25s;

    &:hover{
      color: $yellow;
    }

    &.nav-left{
      padding-right: 10px;
    }
  
    &.nav-right{
      padding-left: 10px;
    }

    @include media-breakpoint-up(md){
      padding: 10px;

      &.nav-left{
        padding-right: $grid-gutter / 2;
      }
    
      &.nav-right{
        padding-left: $grid-gutter / 2;
      }
    }
  }

  
}

.chartContainer{
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    
    .chart-wrap{
      width: 100%;
      min-height: 500px;
      background: $red;
      //padding: 10px;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(md){
        padding: $grid-gutter/ 2;
        min-height: 700px;
      }

      main{
        flex: 1;
      }

      &.color-green{
        background-color: $green;
      }
      
      &.color-yellow{
        background-color: $yellow;
      }

      &.color-yellow{
        background-color: $blue;
      }
    }
  }