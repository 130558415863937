@import "../../assets/styles/variables";

.swingstates-chart {
  

  .swingstates-chart-main {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content:flex-start;
  }
}
