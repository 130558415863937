@import '../../assets/styles/variables';

.video-player{
    //display: inline;

    .video-player-toggle{
        white-space: normal;
        cursor: pointer;
    }

    .video-player-wrap{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, .95);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $white;
        z-index: 99;

        .video-player-container{
            width: 100%;
            max-width: 1000px;
            margin-bottom: $grid-gutter / 2;
            
            .video-player-ratio{
                position: relative;
                width: 100%;
                height: 0;
                padding-top: 56.25%;
    
                &>div{
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
            }
        }
    }
}