@import '../../assets/styles/variables';

.swingstates-page {
	background-color: $blue;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;

	.swingstates-main {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: top;
		text-align: center;
		color: $white;

		.state-name {
			font-size: 2em;
			padding: 10px;

			a:hover{
				color: $yellow;
			}
		}
	}
	
	.state-name-link {
		text-decoration: none;
		color: $white;
	}

}








