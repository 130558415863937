@import "../../assets/styles/variables";

.chart-main {
  height:100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  header{
    h3{
      margin-bottom: $grid-gutter;
    }
  }

  .chart {
    flex: 1;
  }

  //Get rid of branding
  g[opacity="0.4"],
  g[opacity="0.3"],
  g[opacity="0.2"],
  g[opacity="0.1"]{
    display: none;
  }

  .swing-circle-selectors {
    display: flex;
    justify-content: space-around;

    .selectors {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5px 5px 10px;
      cursor: pointer;

      .selectors-text{
        padding: 3px 5px;
      }

      &.active{
        .selectors-text{
          background-color: $black;
          color: $white;
        }
      }

      .arrow-up {
        border-right: 5px solid transparent;
        border-bottom: 5px solid black;
        border-left: 5px solid transparent;
        width: 0;
        height: 0;
      }
    }
  }
}