@import '../../assets/styles/variables';

#mc_embed_signup{
    clear:left; 

    form {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      width: 100%;
      max-width: 450px;
      margin-bottom: 30px;
  
      input, button {
        background: transparent;
        text-align: center;
        padding: 1vh;
        width: 100%;
        color: $white;
        box-sizing: border-box;
        font-family: $anon-pro;
        font-size: 1em;
        border-style: solid;
        border-width: 1px;
      }
  
      input[type="text"] {
        border: 2px solid grey;
        border-bottom: none;
      }
  
      button[type="submit"] {
        background: $red;
        border: none;
        text-transform: uppercase;
      }
  
      .msg-alert{
        padding-top: 5px;
      }
    }  
}


