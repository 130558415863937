@import "../../assets/styles/variables";

#quiz-page{
  background: $beige;
  min-height: 100vh;
}

.quiz-main-wrapper {
  color: $black;
  font-family: $anon-pro;
  text-align: center;
}

.progress-marker-wrapper {
  height: 10px;
  max-width: 400px;
  display: flex;
  flex-direction: row;
  border: 1px solid $black;
  margin: 0 auto;
}

.progress-marker-bar {
  border: 1px solid $black;  
  width: 10%;

  &.active{
    background-color: $black;
  }

  &.passed{
    background-color: $white;
  }
}

.question-heading {
  size: 30px;
}

.question-text {
  font-family: $spoof;
  padding: 0;
  font-size: 25px;
  line-height: 1;
  font-weight: 500;
  margin-top: 20px;
}

.result-description {
  size: 20px;
  margin-top: 50px;
}

.multiple-choice-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.option-button {
  color: $black;
  width: 225px;
  height: 50px;
  margin: 10px 0;
  border: 1px solid $black;  
  background: #EDE9E4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: background .5s;

  &:active {
    cursor: pointer;
    background: $green;
  }

  @include media-breakpoint-up(md){
    &:hover {
      cursor: pointer;
      background: $green;
    }
  }
}



