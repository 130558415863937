@import '../../assets/styles/variables';

.landing-page{
    padding: 0!important;

    section{
        padding: $grid-gutter 0;

        @include media-breakpoint-up(md){
            padding: 200px 0;
        }

        h2{
            span{
                color: $yellow;
            }
        }
    }

    #intro{
        position: relative;
        min-height: 100vh;
        padding: 100px 0 0;
        //overflow-x: hidden;
        
        #arrow{
            position: absolute;
            bottom: $grid-gutter;
            left: 0;
            width: 40%;
        }

        #sign{
            position: absolute;
            bottom: $grid-gutter;
            left: 50%;
            width: 50%;

            img{
                width: 100%;
            }
        }

        @include media-breakpoint-up(md){
            padding-top: 200px;

            h2{
                padding-bottom: 100px;
            }

            #arrow{
                bottom: 0;
                left: 10%;
                width: 25%;
            }

            #sign{
                bottom: 0;
                left: 50%;
                width: 40%;
                max-width: 500px;
            }
        }

        @include media-breakpoint-up(lg){
            display: flex;
            align-items: center;
            padding-top: 0;

            #arrow{
                left: 40%;
                width: 15%;
            }

            #sign{
                left: 60%;
                width: 30%;
                max-width: 500px;
            }
        }
    }
    
    #interviews{
        .number{
            color: $red;
            text-align: center;
            font-size: 80px;

            @include media-breakpoint-up(md){
                font-size: 150px;
            }
        }

        h4{
            text-align: left;
            margin-bottom: 0;

            &:first-child{
                margin-top: 0;
            }
        }

        .explainer-video{
            margin-top: $grid-gutter * 2;
            text-align: center;

            @include media-breakpoint-up(md){
                margin-top: 200px;
            }

            h3{
                margin-bottom: $grid-gutter;
            }
        }
    }

    #quizQuestion{
        background-color: $purple;

        .wrap{
            position: relative;

            .bubble{
                display: none;

                @include media-breakpoint-up(md){
                    display: block;
                    position: absolute;
                    top: -250px;
                    right: 0;
                }
            }
        }

        h3{
            color: $black;
        }
    }

    #reasons{
        padding-bottom: 0;

        h2{
            margin-bottom: $grid-gutter;
        }

        .chart-wrap{
            min-height: 500px;
        }
    }

    #types{
        background-color: $purple;
        position: relative;
        padding-top: 0;

        .type{
            display: none;
            position: absolute;
            animation: rotation 10s;
            animation-iteration-count: infinite;

            @include media-breakpoint-up(xl){
                display: block;
            }

            &:nth-child(1){
                top: $grid-gutter;
                left: 200px;
                animation-delay: 3s;
            }
            
            &:nth-child(2){
                top: $grid-gutter;
                right: 200px;
            }

            &:nth-child(3){
                top: 50%;
                left: $grid-gutter * 2;
                animation-delay: 5s;
            }

            &:nth-child(4){
                top: 50%;
                right: $grid-gutter * 2;
                animation-delay: 6s;
            }

            &:nth-child(5){
                bottom: -100px;
                left: 300px;
                animation-delay: 7s;
                animation-direction: reverse;
            }

            &:nth-child(6){
                bottom: -100px;
                right: 300px;
                animation-delay: 1s;
            }
        }
    }

    #swingStates{
        display: block;
        padding-top: 100px;

        @include media-breakpoint-up(md){
            padding-top: 300px;
        }

        .swing-animation{
            margin-bottom: $grid-gutter * 2;

            .swing-text{
                color: $white;
                border-bottom: 20px solid $purple;
                font-size: 30px;
                line-height: 20px;
                font-family: $spoof;
                text-transform: uppercase;
                display: flex;
                justify-content: space-between;

                transform: rotateZ(10deg);

                @include media-breakpoint-up(sm){
                    font-size: 70px;
                    line-height: 50px;
                    border-bottom-width: 30px;
                }
                
                @include media-breakpoint-up(lg){
                    font-size: 100px;
                    line-height: 70px;
                    border-bottom-width: 50px;
                }
            }

            .swing-triangle{
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 30px 60px 30px;
                border-color: transparent transparent $green transparent;
                margin: 0 auto 0;

                @include media-breakpoint-up(md){
                    border-width: 0 50px 100px 50px;
                    margin: -$grid-gutter / 2 auto 0;
                }
            }
        }

        #swingData{
            margin-top: $grid-gutter * 2;
            padding: $grid-gutter / 2 0;
            //border: 2px solid $white;
            min-height: 800px;
            text-align: center;
            background-color: $red;

            @include media-breakpoint-up(md){
                padding: $grid-gutter $grid-gutter / 2;
            }

            .swingstates-chart{
                background-color: transparent;

                .top{
                    padding: 0 10px;
                    margin-bottom: $grid-gutter / 2;

                    h3{
                        font-size: 1;
                    }
                }
                
                .chartContainer .chart-wrap{
                    background-color: transparent;

                    .swing-circle-selectors {
                    
                        .selectors {

                            &.active{
                                .selectors-text{
                                  background-color: $yellow;
                                  color: $black;
                                }
                            }
                    
                          .arrow-up {
                            border-bottom-color: $yellow;
                          }
                        }
                      }
                }
            }
        }
    }

    #demography{
        background: $purple;
    }

    #personalImpact{
        background-color: $yellow;
        color: $black;

        .video-block{
            margin-top: $grid-gutter;
        }
    }

    #mistrust{
        background-color: $green;
        color: $black;

        a{
            color: $yellow;
        }
    }

    #news{

        @include media-breakpoint-up(md){
            .news-intro{
                margin-bottom: $grid-gutter * 2;
            }
        }
        

        .emerging-intro{
            padding-top: 200px;
        }
    }

    #emerging{
        text-align: center;
        padding-top: $grid-gutter;

        #dashes{
            height: 800px;

            .dashes-wrap{
                width: 5px;
                background: url('../../assets/images/dashes-dot.png');
                background-size: contain;
                margin: $grid-gutter auto;
            }
        }

        #emerging-numbers{
            opacity: 0;
            transform: scale(0);
            padding-top: $grid-gutter;

            h3{
                color: $yellow;
            }

            .number{
                line-height: 1em;
            }
        }

        p{
            max-width: $wrap-sm;
            margin: auto;
        }
    }

    #emergingText{
        padding-top: 0;
    }

    #browser{
        min-height: 100vh;
        display: flex;
        align-items: center;

        .left, 
        .right{
            display: none;

            @include media-breakpoint-up(lg){
                display: block;
            }
        }

        .right{
            margin-top: $grid-gutter * 3;
        }
        
        .popup-wrap{
            position: relative;
        }

        .highlight{
            border: 2px solid $red;
            padding: 25px;
            text-align: center;

            .number{
                font-size: 80px;
                color: $red;
            }
        }
    }

    #losingVoters{
        padding-top: 0;

        .disappear{
            color: $white;
        }
        
        h4{
            margin-bottom: 100px;

            @include media-breakpoint-up(md){
                margin-bottom: 400px;
            }
        }
    }

    #conclusion{
        background-color: $white;
        color: $black;
        padding-top: 100px;
        padding-bottom: 200px;

        .text{
            margin-bottom: $grid-gutter;
        }

        .box{
            margin-top: $grid-gutter;
        }
        
        @include media-breakpoint-up(md){

            .box{
                margin-top: 0;
            }
        }

        @include media-breakpoint-up(lg){
            padding-bottom: 100px;

            .box{
                margin-top: $grid-gutter;
            }
        }

        //Capitol image
        background-image: url('/images/capitol_header.png');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 60%;

        @include media-breakpoint-up(md){
            background-size: 45%;
        }
    }

    #next{
        padding: 0;

        .next-item{
            text-align: center;
            padding: 100px $grid-gutter;

            @include media-breakpoint-up(md){
                padding: 100px $grid-gutter / 2;
            }
            
            @include media-breakpoint-up(lg){
                padding: 100px $grid-gutter;
            }

            h2{
                text-transform: uppercase;
            }

            &#learn{
                background-color: $purple;
            }
            &#watch{
                background-color: $red;
            }
            &#explore{
                background-color: $blue;
            }
        }
    }

    .box{
        padding: $grid-gutter / 2;
        text-align: center;

        &.black{
            background-color: $black;
            color: $white;
        }

    }

    .number{
        text-align: center;
        color: $yellow;
        font-family: $spoof;
        font-size: 100px;

        @include media-breakpoint-up(md){
            font-size: 200px;
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotateZ(0);
      }
    
    90% {
        transform: rotateZ(0);
      }
    
      100% {
        transform: rotateZ(360deg);
      }
}