@import "../../assets/styles/variables";

.chart-select {
  margin: $grid-gutter / 2 auto 0;
  max-width: 400px;

  .selector {
    display: inline-block;
    height: 10px;
    width: 10px;
    background: $white;
    opacity: .5;
    border-radius: 50%;
    padding: 5px;
    margin: 0 10px;
    cursor: pointer;

    &:hover,
    &.active{
      opacity: 1;
    }
  }
}
